import loadable from '@loadable/component';
import { PureComponent } from 'react';
import {
  TYPE_CATEGORY,
  TYPE_CMS_PAGE,
  TYPE_NOTFOUND,
  TYPE_PRODUCT,
} from '@/theme/route/MageRewrites/Config';
import SomethingWentWrong from '../SomethingWentWrong';

const ProductPage = loadable(
  () =>
    import(/* webpackChunkName: "mw-product" */ '@/theme/route/ProductPage'),
  {
    ssr: true,
  }
);
const CategoryPage = loadable(
  () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "mw-category" */ '@/theme/route/CategoryPage'
    ),
  {
    ssr: true,
  }
);
const CmsPage = loadable(
  () => import(/*  webpackMode: "lazy",  webpackChunkName: "mw-cms" */ '@/theme/route/CmsPage'),
  {
    ssr: true,
  }
);
const NoMatch = loadable(
  () => import(/*  webpackMode: "lazy", webpackChunkName: "mw-cms" */ '@/theme/route/NoMatch'),
  {
    ssr: true,
  }
);

/** @namespace Route/MageRewrites/MageRewritesRenderer */
export default function MageRewritesRenderer(props) {
  const type = props.type;
  switch (type) {
    case TYPE_PRODUCT:
      return <ProductPage {...props} />;
    case TYPE_CMS_PAGE:
      return <CmsPage {...props} />;
    case TYPE_CATEGORY:
      return <CategoryPage {...props} />;
    case TYPE_NOTFOUND:
      return <SomethingWentWrong {...props} />;
    default:
      return <NoMatch {...props} />;
  }
}

MageRewritesRenderer[TYPE_PRODUCT] = ProductPage;
MageRewritesRenderer[TYPE_CMS_PAGE] = CmsPage;
MageRewritesRenderer[TYPE_CATEGORY] = CategoryPage;
MageRewritesRenderer[TYPE_NOTFOUND] = SomethingWentWrong;
MageRewritesRenderer.NoMatch = NoMatch;
